export const GET_ANILLOS_FAIL = "GET_ANILLOS_FAIL";
export const GET_ANILLOS_SUCCESS = "GET_ANILLOS_SUCCESS";
export const GET_ANILLO_FAIL = "GET_ANILLO_FAIL";
export const GET_ANILLO_SUCCESS = "GET_ANILLO_SUCCESS";
export const GET_ANILLOS_NAME_SUCCESS = "GET_ANILLOS_NAME_SUCCESS";
export const GET_ANILLOS_NAME_FAIL = "GET_ANILLOS_NAME_FAIL";
export const GET_ANILLOS_SIZE_SUCCESS = "GET_ANILLOS_SIZE_SUCCESS";
export const GET_ANILLOS_SIZE_FAIL = "GET_ANILLOS_SIZE_FAIL";
export const GET_ANILLOS_PUREZA_SUCCESS = "GET_ANILLOS_PUREZA_SUCCESS";
export const GET_ANILLOS_PUREZA_FAIL = "GET_ANILLOS_PUREZA_FAIL";
export const GET_ANILLOS_PESONETO_SUCCESS = "GET_ANILLOS_PESONETO_SUCCESS";
export const GET_ANILLOS_PESONETO_FAIL = "GET_ANILLOS_PESONETO_FAIL";
export const GET_ANILLOS_PRECIOUNIDAD_SUCCESS =
  "GET_ANILLOS_PRECIOUNIDAD_SUCCESS";
export const GET_ANILLOS_PRECIOUNIDAD_FAIL = "GET_ANILLOS_PRECIOUNIDAD_FAIL";
export const GET_SEARCH_ANILLO_SUCCESS = "GET_SEARCH_ANILLOS_SUCCESS";
export const GET_SEARCH_ANILLO_FAIL = "GET_SEARCH_ANILLOS_FAIL";
