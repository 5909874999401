export const GET_DIJES_FAIL = "GET_DIJES_FAIL";
export const GET_DIJES_SUCCESS = "GET_DIJES_SUCCESS";
export const GET_DIJE_FAIL = "GET_DIJE_FAIL";
export const GET_DIJE_SUCCESS = "GET_DIJE_SUCCESS";
export const GET_DIJES_NAME_SUCCESS = "GET_DIJES_NAME_SUCCESS";
export const GET_DIJES_NAME_FAIL = "GET_DIJES_NAME_FAIL";
export const GET_DIJES_SIZE_SUCCESS = "GET_DIJES_SIZE_SUCCESS";
export const GET_DIJES_SIZE_FAIL = "GET_DIJES_SIZE_FAIL";
export const GET_DIJES_PUREZA_SUCCESS = "GET_DIJES_PUREZA_SUCCESS";
export const GET_DIJES_PUREZA_FAIL = "GET_DIJES_PUREZA_FAIL";
export const GET_DIJES_PESONETO_SUCCESS = "GET_DIJES_PESONETO_SUCCESS";
export const GET_DIJES_PESONETO_FAIL = "GET_DIJES_PESONETO_FAIL";
export const GET_DIJES_PRECIOUNIDAD_SUCCESS = "GET_DIJES_PRECIOUNIDAD_SUCCESS";
export const GET_DIJES_PRECIOUNIDAD_FAIL = "GET_DIJES_PRECIOUNIDAD_FAIL";
export const GET_SEARCH_DIJE_SUCCESS = "GET_SEARCH_DIJES_SUCCESS";
export const GET_SEARCH_DIJE_FAIL = "GET_SEARCH_DIJES_FAIL";
