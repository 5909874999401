export const GET_ARETES_FAIL = "GET_ARETES_FAIL";
export const GET_ARETES_SUCCESS = "GET_ARETES_SUCCESS";
export const GET_ARETE_FAIL = "GET_ARETE_FAIL";
export const GET_ARETE_SUCCESS = "GET_ARETE_SUCCESS";
export const GET_ARETES_NAME_SUCCESS = "GET_ARETES_NAME_SUCCESS";
export const GET_ARETES_NAME_FAIL = "GET_ARETES_NAME_FAIL";
export const GET_ARETES_SIZE_SUCCESS = "GET_ARETES_SIZE_SUCCESS";
export const GET_ARETES_SIZE_FAIL = "GET_ARETES_SIZE_FAIL";
export const GET_ARETES_PUREZA_SUCCESS = "GET_ARETES_PUREZA_SUCCESS";
export const GET_ARETES_PUREZA_FAIL = "GET_ARETES_PUREZA_FAIL";
export const GET_ARETES_PESONETO_SUCCESS = "GET_ARETES_PESONETO_SUCCESS";
export const GET_ARETES_PESONETO_FAIL = "GET_ARETES_PESONETO_FAIL";
export const GET_ARETES_PRECIOUNIDAD_SUCCESS =
  "GET_ARETES_PRECIOUNIDAD_SUCCESS";
export const GET_ARETES_PRECIOUNIDAD_FAIL = "GET_ARETES_PRECIOUNIDAD_FAIL";
export const GET_SEARCH_ARETE_SUCCESS = "GET_SEARCH_ARETES_SUCCESS";
export const GET_SEARCH_ARETE_FAIL = "GET_SEARCH_ARETES_FAIL";
