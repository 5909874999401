export const GET_PIRCINGS_FAIL = "GET_PIRCINGS_FAIL";
export const GET_PIRCINGS_SUCCESS = "GET_PIRCINGS_SUCCESS";
export const GET_PIRCING_FAIL = "GET_PIRCING_FAIL";
export const GET_PIRCING_SUCCESS = "GET_PIRCING_SUCCESS";
export const GET_PIRCINGS_NAME_SUCCESS = "GET_PIRCINGS_NAME_SUCCESS";
export const GET_PIRCINGS_NAME_FAIL = "GET_PIRCINGS_NAME_FAIL";
export const GET_PIRCINGS_SIZE_SUCCESS = "GET_PIRCINGS_SIZE_SUCCESS";
export const GET_PIRCINGS_SIZE_FAIL = "GET_PIRCINGS_SIZE_FAIL";
export const GET_PIRCINGS_PUREZA_SUCCESS = "GET_PIRCINGS_PUREZA_SUCCESS";
export const GET_PIRCINGS_PUREZA_FAIL = "GET_PIRCINGS_PUREZA_FAIL";
export const GET_PIRCINGS_PESONETO_SUCCESS = "GET_PIRCINGS_PESONETO_SUCCESS";
export const GET_PIRCINGS_PESONETO_FAIL = "GET_PIRCINGS_PESONETO_FAIL";
export const GET_PIRCINGS_PRECIOUNIDAD_SUCCESS =
  "GET_PIRCINGS_PRECIOUNIDAD_SUCCESS";
export const GET_PIRCINGS_PRECIOUNIDAD_FAIL = "GET_PIRCINGS_PRECIOUNIDAD_FAIL";
export const GET_SEARCH_PIRCING_SUCCESS = "GET_SEARCH_PIRCINGS_SUCCESS";
export const GET_SEARCH_PIRCING_FAIL = "GET_SEARCH_PIRCINGS_FAIL";
