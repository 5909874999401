export const GET_CADENAS_FAIL = "GET_CADENAS_FAIL";
export const GET_CADENAS_SUCCESS = "GET_CADENAS_SUCCESS";
export const GET_CADENA_FAIL = "GET_CADENA_FAIL";
export const GET_CADENA_SUCCESS = "GET_CADENA_SUCCESS";
export const GET_CADENAS_NAME_SUCCESS = "GET_CADENAS_NAME_SUCCESS";
export const GET_CADENAS_NAME_FAIL = "GET_CADENAS_NAME_FAIL";
export const GET_CADENAS_SIZE_SUCCESS = "GET_CADENAS_SIZE_SUCCESS";
export const GET_CADENAS_SIZE_FAIL = "GET_CADENAS_SIZE_FAIL";
export const GET_CADENAS_PUREZA_SUCCESS = "GET_CADENAS_PUREZA_SUCCESS";
export const GET_CADENAS_PUREZA_FAIL = "GET_CADENAS_PUREZA_FAIL";
export const GET_CADENAS_PESONETO_SUCCESS = "GET_CADENAS_PESONETO_SUCCESS";
export const GET_CADENAS_PESONETO_FAIL = "GET_CADENAS_PESONETO_FAIL";
export const GET_CADENAS_PRECIOUNIDAD_SUCCESS =
  "GET_CADENAS_PRECIOUNIDAD_SUCCESS";
export const GET_CADENAS_PRECIOUNIDAD_FAIL = "GET_CADENAS_PRECIOUNIDAD_FAIL";
export const GET_SEARCH_CADENA_SUCCESS = "GET_SEARCH_CADENAS_SUCCESS";
export const GET_SEARCH_CADENA_FAIL = "GET_SEARCH_CADENAS_FAIL";
