export const GET_BRAZALETES_FAIL = "GET_BRAZALETES_FAIL";
export const GET_BRAZALETES_SUCCESS = "GET_BRAZALETES_SUCCESS";
export const GET_BRAZALETE_FAIL = "GET_BRAZALETE_FAIL";
export const GET_BRAZALETE_SUCCESS = "GET_BRAZALETE_SUCCESS";
export const GET_BRAZALETES_NAME_SUCCESS = "GET_BRAZALETES_NAME_SUCCESS";
export const GET_BRAZALETES_NAME_FAIL = "GET_BRAZALETES_NAME_FAIL";
export const GET_BRAZALETES_SIZE_SUCCESS = "GET_BRAZALETES_SIZE_SUCCESS";
export const GET_BRAZALETES_SIZE_FAIL = "GET_BRAZALETES_SIZE_FAIL";
export const GET_BRAZALETES_PUREZA_SUCCESS = "GET_BRAZALETES_PUREZA_SUCCESS";
export const GET_BRAZALETES_PUREZA_FAIL = "GET_BRAZALETES_PUREZA_FAIL";
export const GET_BRAZALETES_PESONETO_SUCCESS =
  "GET_BRAZALETES_PESONETO_SUCCESS";
export const GET_BRAZALETES_PESONETO_FAIL = "GET_BRAZALETES_PESONETO_FAIL";
export const GET_BRAZALETES_PRECIOUNIDAD_SUCCESS =
  "GET_BRAZALETES_PRECIOUNIDAD_SUCCESS";
export const GET_BRAZALETES_PRECIOUNIDAD_FAIL =
  "GET_BRAZALETES_PRECIOUNIDAD_FAIL";
export const GET_SEARCH_BRAZALETE_SUCCESS = "GET_SEARCH_BRAZALETES_SUCCESS";
export const GET_SEARCH_BRAZALETE_FAIL = "GET_SEARCH_BRAZALETES_FAIL";
